import log from "loglevel";
import { getCLS, getFCP, getFID, getLCP, getTTFB, ReportHandler } from "web-vitals";

const thresholds = {
  LCP: {
    title: "Largest Contentful Paint",
    warn: 2500,
    error: 4000,
    unit: "ms",
  },
  FID: {
    title: "First Input Delay",
    warn: 100,
    error: 300,
    unit: "ms",
  },
  CLS: {
    title: "Cumalative Layout Shift",
    warn: 0.1,
    error: 0.25,
    unit: "",
  },
  FCP: {
    title: "First Contentful Paint",
    warn: 2000,
    error: 4000,
    unit: "ms",
  },
  TTFB: {
    title: "Time To First Byte",
    warn: 200,
    error: 400,
    unit: "ms",
  },
};

const defaultReporter: ReportHandler = ({ name, value }) => {
  const { title, error, warn, unit } = thresholds[name];

  if (value > error) {
    log.error(`Web Vitals Error: ${title} value is above ${error}${unit} (received ${value}${unit})`);
  } else if (value > warn) {
    log.warn(`Web Vitals Warning: ${title} value is above ${warn}${unit} (received ${value}${unit})`);
  } else {
    log.debug(`Web Vitals Info: ${title} value is ${value}${unit} (Warn threshold ${warn}${unit})`);
  }
};

export const reportWebVitals = ({
  reporter = defaultReporter,
  enabled = process.env.NODE_ENV !== "production",
} = {}): void => {
  if (!enabled) {
    return;
  }

  getCLS(reporter);
  getFID(reporter);
  getLCP(reporter);
  getFCP(reporter);
  getTTFB(reporter);
};
