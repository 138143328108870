import { lazy, StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import log from "loglevel";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material";
import { LoadingSpinner, muiTheme } from "@covema-vernici/components";
import { LicenseInfo } from "@mui/x-license-pro";
import { reportWebVitals } from "./reportWebVitals";

LicenseInfo.setLicenseKey(
  "ead6c56c4eb11df285cff04efb873f1aT1JERVI6MzY2NjgsRVhQSVJZPTE2NzQ4MTk5MTUwMDAsS0VZVkVSU0lPTj0x",
);

const App = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName App */
      "./App"
    ),
);

log.setDefaultLevel(log.levels.TRACE);

// TODO: web-vitals

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={muiTheme}>
      <Suspense fallback={<LoadingSpinner variant="absolute" />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
